<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
            <v-toolbar-title class="text-overline" style="font-size: 16px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;">
                    <!-- <span class="green--text text--darken-1">Emplus</span> -->
                    <v-img alt="Emplus" src="@/assets/logo/emplus/emplus_logotype_light_135x48.png" class="mr-1 float-left" width="101" height="36" fit="inside"></v-img>
                </router-link>
            </v-toolbar-title>
            <!-- 240x48, 180x36, 120x24, 90x18 -->
            <!-- <router-link to="/">
                <v-img
                alt="Webauthz"
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo/Webauthz_nobg_240x48.png')"
                transition="scale-transition"
                width="120"
                height="24"
                />
                </router-link> -->
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="teal darken-2"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <!-- TODO: add search feature, then enable this button -->
            <!-- <v-btn icon color="teal darken-2" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn> -->
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon color="teal darken-2" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a href="https://liberty.io/contact/" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="teal darken-2" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on">
                                <font-awesome-icon right class="green--text" icon="bars" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="teal darken-2">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <v-btn outlined color="teal darken-2" v-if="!isAuthenticated" @click="login">
                <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                <!-- <font-awesome-icon :icon="['fas','question']" fixed-width/> -->
                Sign in
            </v-btn>
            <v-menu offset-y open-on-click open-on-hover close-delay="100" bottom left v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="teal--text text--darken-2">
                        <Avatar :attr="mainProfile" :size="36"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" class="teal--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-account-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="teal--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" class="teal--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" class="teal--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" class="teal--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        Avatar,
    },
    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            this.$router.push({ path: '/login', query: { next: fullPath } });
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
    },

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realmId) {
            console.log(`navabar realm ${this.$route.params.realmId}`);
        } else {
            console.log('navbar no realm');
        }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
